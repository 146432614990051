var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dapp--eth-blocks--block-search" },
    [
      _c("p", { staticClass: "primary--text mb-1 ml-2" }, [
        _vm._v("Max block: " + _vm._s(_vm.maxBlock)),
      ]),
      _c("date-selector-popup", {
        attrs: {
          "show-popup": _vm.showDate,
          "search-date": _vm.searchByDate,
          "hide-popup": _vm.hidePopup,
        },
      }),
      _c("mew-search", {
        attrs: {
          "is-search-block": "",
          placeholder: "Enter a block number",
          value: _vm.searchBlock,
          "on-search": _vm.search,
          "can-search-date": "",
          "on-date-search": _vm.showDatePopup,
          "error-messages": _vm.searchErrorMessage,
          type: "number",
        },
        on: { input: _vm.setBlock },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }